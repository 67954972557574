import getStructuredData from "@/components/storyblok/seo/structuredDataEvent"
import mapper, { imageMediaFormat } from "@/helpers/mapping"

const IMAGE_TYPE = mapper.mapValue(imageMediaFormat)

export default {
    head() {
        const story = this.story
        if (!story?.content) return
        let { title, description, image, favicon, noIndex, noManifest } =
            story.content
        const head = { meta: [], link: [] }
        head.meta.push({
            hig: "og:url",
            name: "og:url",
            content: this.$router.fullPath,
        })
        if (title) {
            head.title = title
            head.meta.push({
                hid: "og:title",
                name: "og:title",
                content: title,
            })
            head.meta.push({
                hig: "apple-mobile-web-app-title",
                name: "apple-mobile-web-app-title",
                content: title,
            })
            head.meta.push({
                hid: "og:site_name",
                name: "og:site_name",
                content: title,
            })
        }
        if (description) {
            if (description.length > 320) {
                description = description.substring(0, 317) + "..."
            }
            head.meta.push(
                {
                    hid: "description",
                    name: "description",
                    content: description,
                },
                {
                    hid: "og:description",
                    name: "og:description",
                    content: description,
                }
            )
        }
        if (image) {
            head.meta.push(
                {
                    hid: "og:image",
                    name: "og:image",
                    content: image.filename,
                },
                {
                    hid: "og:image:alt",
                    name: "og:image:alt",
                    content: image.alt,
                }
            )
        }
        if (favicon) {
            const fileEnding = favicon.filename.split(".").pop()
            head.link.push({
                hid: "favicon",
                rel: "icon",
                type: IMAGE_TYPE(fileEnding) ?? "image/x-icon",
                href: favicon.filename,
            })
            head.link.push({
                hid: "apple-touch-icon",
                rel: "apple-touch-icon",
                type: IMAGE_TYPE(fileEnding) ?? "image/x-icon",
                href: favicon.filename,
            })
            head.link.push({
                hid: "shortcut-icon",
                rel: "shortcut-icon",
                type: IMAGE_TYPE(fileEnding) ?? "image/x-icon",
                href: favicon.filename,
            })
        }
        if (noIndex) {
            head.meta.push({
                hid: "robots",
                name: "robots",
                content: "noindex",
            })
        }
        if (noManifest) {
            head.link.push({
                hid: "manifest",
                rel: "manifest",
                href: "",
            })
        }
        const headContent = story.content?.headContent || []
        const event = headContent.find(
            (el) => el.component === "seo-structuredDataEvent"
        )
        if (event) {
            head.__dangerouslyDisableSanitizers = ["script"]
            head.script = [
                {
                    innerHTML: JSON.stringify(
                        getStructuredData.call(this, event)
                    ),
                    type: "application/ld+json",
                },
            ]
        }
        return head
    },
}
