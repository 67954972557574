export default function getStructuredData(data) {
    return [
        {
            "@context": "https://schema.org",
            "@type": "Event",
            name: data.name,
            identifier: {
                "@type": "PropertyValue",
                name: "Oabat",
                value: data.identifier,
            },
            startDate: data.startDate,
            endDate: data.endDate,
            eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
            eventStatus: "https://schema.org/EventScheduled",
            image: [data.image.filename],
            description: data.description,
            isAccessibleForFree: "True",
            performer: metaPerformer.call(this),
            location: {
                "@type": "VirtualLocation",
                name: "Oabat.de",
                image: data.image.filename,
                mainEntityOfPage: {
                    "@type": "WebPage",
                    "@id": this.$config.baseURL + this.$route.path,
                },
                url: this.$config.baseURL + this.$route.path,
            },
            organizer: {
                "@type": "Organization",
                name: "Oabat",
                legalName: "Oabat GmbH",
                url: this.$config.baseURL,
                logo: require("@/static/icon.png"),
                description:
                    "Die Stellenb&#246;rse f&#252;r Ausbildung, Sch&#252;lerpraktika und Duales Studium",
                address: {
                    "@type": "PostalAddress",
                    streetAddress: "Innstraße 69b",
                    addressLocality: "Passau",
                    addressRegion: "Bayern",
                    postalCode: "94032",
                    addressCountry: "Deutschland",
                },
                location: {
                    "@type": "Place",
                    address: {
                        "@type": "PostalAddress",
                        addressLocality: "Passau",
                        postalCode: "94032",
                        addressRegion: "Bayern",
                        streetAddress: "Innstraße 69b",
                        addressCountry: "Deutschland",
                    },
                },
            },
        },
    ]
}

function metaPerformer() {
    if (this.businesses?.length > 0) {
        const performers = []
        this.businesses.forEach(function (b) {
            performers.push({
                "@type": "Organization",
                name: b.name,
                legalName: b.name,
            })
        })
        return performers
    } else {
        return {
            "@type": "Organization",
            name: "Oabat",
            legalName: "Oabat GmbH",
            url: this.$config.baseURL,
            logo: require("@/static/icon.png"),
        }
    }
}
