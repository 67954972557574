
import storyblokMeta from "@/mixins/storyblokMeta"
import { defineComponent } from "@nuxtjs/composition-api"
import storyblokService from "@/services/storyblok.service"
import { useStoryblokBridge } from "@storyblok/nuxt-2"

export default defineComponent({
    name: "CustomDomainRoute",
    meta: {
        toolbar: {
            hidden: true,
        },
        footer: {
            showPowered: true,
        },
    },
    data: () => ({
        story: null,
        isEditingMode: false,
        channelSlug: null,
    }),
    layout: "customdomain",
    mixins: [storyblokMeta],

    beforeRouteUpdate(to, from, next) {
        if (this.story.content.hideFooter) {
            this.$store.dispatch("meta/addMeta", { footer: { hidden: true } })
        }
        next()
    },
    async asyncData({ route, req, error, $config }) {
        let domain = ""
        if ($config?.forcedCustomDomain) {
            domain = $config.forcedCustomDomain
                .replace("www.", "")
                .replaceAll(".", "-")
        } else if (process.server) {
            domain = req.headers.host.replace("www.", "").replaceAll(".", "-")
        } else {
            domain = window.location.host
                .replace("www.", "")
                .replaceAll(".", "-")
        }
        let path = route.path

        const isEditingMode = route.query?._storyblok !== undefined
        const version = isEditingMode ? "draft" : "published"
        const story = await storyblokService
            .getStory(`${domain}/${path}`, version)
            .catch(() =>
                error({
                    statusCode: 404,
                    message: "Inhalt wurde nicht gefunden.",
                })
            )
        const channelSlug = story?.content?.channel
        return { story, channelSlug, isEditingMode }
    },
    provide() {
        return {
            channelSlug: this.channelSlug,
        }
    },
    mounted() {
        if (this.isEditingMode && this.story?.id) {
            useStoryblokBridge(
                this.story.id,
                (evStory) => {
                    this.story = evStory
                },
                { preventClicks: true }
            )
        }
    },
})
