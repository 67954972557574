import { useStoryblokBridge, useStoryblokApi } from "@storyblok/nuxt-2"

export default {
    getStory(route, version = "published") {
        return useStoryblokApi()
            .get(`cdn/stories/${route}`, { version })
            .then(({ data }) => data.story)
    },
    useBridge(id, callback) {
        return useStoryblokBridge(id, callback)
    },
}
